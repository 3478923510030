@import '../../styles/variables';

.header {
  display: flex;
  align-items: center;
}

.header__logo {
  margin-right: 50px;
}

.header__menu-item {
  text-decoration: none;
  cursor: pointer;
  padding: 15px;
}

.header__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__menu-item {
  text-transform: uppercase;
  transition: .3s;
  padding: 10px 25px;
  margin: 0 5px;
  color: $font-color
}

.active {
  border-bottom: 1px solid $antd-color-main;
}
