.report__form-wrapper {
  padding: 20px 0;
  width: 500px;
}

.report__form-block {
  margin-bottom: 20px;
}

.report__fields {
  width: 250px;
}

.report__date-fields {
  width: 100%;
}

.report__column {
  columns: 2;
}

.report__label {
  margin-bottom: 5px;
}